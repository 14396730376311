import { cn } from '~/utils/cn';

type Props = React.ComponentProps<'svg'>;

export const Logo = (props: Props) => (
  <svg viewBox="0 0 512 512" {...props} className={cn('h-6 w-6 fill-black', props.className)}>
    <rect width="512" height="512" />
    <path
      d="M266.586 415.164C286.898 415.164 305.697 411.844 322.982 405.203C340.268 398.562 356.918 388.211 372.934 374.148C379.574 368.484 385.043 362.039 389.34 354.812C393.637 347.586 395.785 341.434 395.785 336.355C395.785 332.059 394.564 328.689 392.123 326.248C389.682 323.807 386.312 322.586 382.016 322.586C375.766 322.586 367.27 326.883 356.527 335.477C351.449 339.383 347.543 342.312 344.809 344.266C336.996 349.539 328.549 353.543 319.467 356.277C310.385 359.012 300.766 360.379 290.609 360.379C261.312 360.379 237.289 348.953 218.539 326.102C199.789 303.25 190.414 273.66 190.414 237.332C190.414 205.887 197.006 181.424 210.189 163.943C223.373 146.463 241.781 137.723 265.414 137.723C277.914 137.723 288.07 140.604 295.883 146.365C303.695 152.127 310.824 162.332 317.27 176.98C324.301 193.582 330.941 204.324 337.191 209.207C343.441 214.09 351.742 216.531 362.094 216.531C372.25 216.531 380.6 213.553 387.143 207.596C393.686 201.639 396.957 193.973 396.957 184.598C396.957 161.16 385.189 140.701 361.654 123.221C338.119 105.74 309.652 97 276.254 97C230.551 97 192.416 112.527 161.85 143.582C131.283 174.637 116 213.699 116 260.77C116 304.52 130.404 341.189 159.213 370.779C188.021 400.369 223.812 415.164 266.586 415.164Z"
      fill="white"
    />
  </svg>
);
